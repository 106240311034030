import { Link } from "react-router-dom";

function SideBarMenus(props: { handleMenuSelect?: (result: any) => void }) {
  const { handleMenuSelect } = props;

  const menus = [
    { menu: "Home", icon: "bi bi-house-door", link: "/" },
    { menu: "User", icon: "bi-people", link: "/users" },
    {
      menu: "Order Details",
      icon: "bi-cart-check",
      link: "/order-details",
    },
    { menu: "Machine", icon: "bi-device-hdd", link: "/machines" },
    {
      menu: "Service Request",
      icon: "bi-person-add",
      link: "/service-request",
    },
    { menu: "Referral", icon: "bi-person-add", link: "/referral" },
    {
      menu: "Notification",
      icon: "bi-bell",
      link: "/service-notification",
    },
    {
      menu: "Notification Config",
      icon: "bi-gear-wide-connected",
      link: "/notification-configuration",
    },
  ];

  return (
    <ul className="nav flex-column mt-2">
      {menus.map((item, idx) => (
        <li className="nav-item" key={`${idx}-menu`}>
          <Link
            className="nav-link text-black"
            to={item.link}
            onClick={() => {
              if (handleMenuSelect) handleMenuSelect(item);
            }}
          >
            <i className={`text-primary me-2 fs-6 bi ${item.icon}`}></i>
            <span className="fs-6">{item.menu}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default SideBarMenus;
