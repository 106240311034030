import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, InputGroup, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getLovByListName } from "../../redux/apis/lovApi";
import {
  createReferralByCode
} from "../../redux/apis/referralApi";
import { COUNTRYCODES } from "../common/helper/helper";
import { MasterValue } from "../common/model";
import "../login/Login.scss";

const AnonymousReferralForm: React.FC = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [referralChannelLov, setReferralChannelLov] = useState<MasterValue[]>(
    []
  );
  const [statusLov, setStatusLov] = useState<MasterValue[]>([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNo: "",
    channelId: "",
    countryCode: "+91",
    statusId: 0,
    lookingFor: "",
    buyPeriod: "",
    usageType:""
  } as any);

  const usageTypeList =[{name: 'Home', value:'Home'},
    {name: 'Commercial', value:'Commercial'},
    {name: 'Industrial', value:'Industrial'},
    {name: 'Other', value:'Other'}
  ]
  useEffect(() => {
    const fetchData = async () => {
      try {
        const referralLovData = await getLovByListName("ReferralChannel");
        const referralStatusLovData = await getLovByListName("ReferralStatus");
        setReferralChannelLov(
          referralLovData.filter((lo: any) => lo.listName === "ReferralChannel")
        );
        setStatusLov(
          referralStatusLovData.filter(
            (lo: any) => lo.listName === "ReferralStatus"
          )
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCountryChange = (selectedCountryCode: string) => {
    setFormData((prevState: any) => ({
      ...prevState,
      countryCode: selectedCountryCode,
    }));
  };

  const handleChangeSelect = (event: any, stateField: string) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [stateField]: event.target.value,
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    handleSave();
  };

  const handleSave = async () => {
    try {
      formData.statusId = statusLov?.find((se) => se.lovCode === "LEAD")?.id!;
      formData.redeemPoints = 0;
      formData.isRedeemed = true;
      formData.isActive = true;
      formData.userId = 0;
      const result = await createReferralByCode(userId || "", formData as any);
      result && navigate("/account/login");
    } catch (error) {
      console.error("Failed to submit referral:", error);
    }
  };

  return (
    <div className="auth-page overflow-y-auto">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Stack gap={3} className="col-8 col-md-3 mx-auto">
          <div className="auth-title">Referral</div>
          <Form.Group controlId="firstName" >
            <Form.Control
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
              autoComplete="off"
              // size="lg"
              placeholder="First Name"
            />
            <Form.Control.Feedback type="invalid">
              Please provide a first name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Control
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
              autoComplete="off"
              // size="lg"
              placeholder="Last Name"
            />
            <Form.Control.Feedback type="invalid">
              Please provide a last name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="emailId">
            <Form.Control
              type="emailId"
              name="emailId"
              value={formData.emailId}
              onChange={handleInputChange}
              required
              autoComplete="off"
              // size="lg"
              placeholder="Email Id"
              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <InputGroup hasValidation>
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id="dropdown-country"
                  className="custom-country-toggle"
                >
                  {formData.countryCode || "+91"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="country-code-dropdown-scroll">
                  {COUNTRYCODES.map((country, index) => (
                    <Dropdown.Item
                      className={
                        formData.countryCode === country.code ? "selected" : ""
                      }
                      key={index}
                      onClick={() => handleCountryChange(country.code)}
                    >
                      ({country.code})-{country.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control
                onChange={handleInputChange}
                name="mobileNo"
                type="tel"
                inputMode="numeric"
                value={formData.mobileNo}
                required
                autoComplete="off"
                pattern="[0-9]{4,15}"
                // size="lg"
                placeholder="Mobile Number"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid mobile number.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group key="channelId">
            <Form.Select
              onChange={(e) => handleChangeSelect(e, "channelId")}
              name="channelId"
              value={formData.channelId}
              // size="lg"
              required
              aria-label="Referred Via"
            >
              <option value="">Referred Via</option>
              {referralChannelLov?.map((category: MasterValue, index) => (
                <option key={index} value={category.id}>
                  {category.lovName}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Referred Via is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="lookingFor">
            <Form.Control
              type="text"
              name="lookingFor"
              value={formData.lookingFor}
              onChange={handleInputChange}
              autoComplete="off"
              // size="lg"
              placeholder="What you are looking for?"
              maxLength={250}
            />
          </Form.Group>
          <Form.Group controlId="buyPeriod">
            <Form.Control
              type="text"
              name="buyPeriod"
              value={formData.buyPeriod}
              onChange={handleInputChange}
              autoComplete="off"
              // size="lg"
              placeholder="How soon you are going to buy?"
              maxLength={100}
            />
          </Form.Group>
          <Form.Group key="usageType">
            <Form.Select
              onChange={(e) => handleChangeSelect(e, "usageType")}
              name="usageType"
              value={formData.usageType}
              // size="lg"
              required
              aria-label="Usage type"
            >
              <option value="">Usage type</option>
              {usageTypeList?.map((category: any, index) => (
                <option key={index} value={category.value}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Usage type is required
            </Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" >
            Submit
          </Button>
        </Stack>
      </Form>
    </div>
  );
};

export default AnonymousReferralForm;
